import { useEffect, useState } from 'react';
import './App.css';
import { Model, Prompt, Report, TalentCombinationResponse, TalentDescriptionResponse } from './types';

const models: Model[] = [
  { name: "GPT-4o Mini", model: "gpt-4o-mini", price: { inputPerM: 0.15, outputPerM: 0.6 }, description: "The affordable and intelligent small model for fast, lightweight tasks." },
  { name: "GPT-4o", model: "gpt-4o", price: { inputPerM: 2.5, outputPerM: 10 }, description: "The high-intelligence flagship model for complex, multi-step tasks." },
  { name: "o1-preview", model: "o1-preview-2024-09-12", price: { inputPerM: 15, outputPerM: 60 }, description: "Language models trained with reinforcement learning to perform complex reasoning." },
];
const prompts: Prompt[] = [
  { name: "Top talents and non-talents", value: "toptalents_nontalents" },
  { name: "Talent combinations", value: "talent_combinations" }
];

function App() {

  const [result, setResult] = useState<TalentCombinationResponse | TalentDescriptionResponse | null>(null);
  const [model, setModel] = useState<Model>(models[0]);
  const [prompt, setPrompt] = useState<Prompt>(prompts[0]);
  const [reportUrl, setReportUrl] = useState<string>("");
  const [ranking, setRanking] = useState<string[]>();
  const [reportType, setReportType] = useState<"individual" | "team">();

  const [error, setError] = useState<string | null>(null);
  const [talentsLoading, setTalentsLoading] = useState<boolean>(false);
  const [promptLoading, setPromptLoading] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);

  const [saveName, setSaveName] = useState<string>("");
  const [reports, setReports] = useState<Report[]>([]);
  const [selectedReport, setSelectedReport] = useState<Report | null>(null);
  const [isShowingSaved, setIsShowingSaved] = useState<boolean>(false);

  const [dragOverIndex, setDragOverIndex] = useState<number | null>(null);
  const [dragPosition, setDragPosition] = useState<'top' | 'bottom' | null>(null);

  const [tab, setTab] = useState<'generate' | 'load'>('generate');

  async function loadTalents() {
    setTalentsLoading(true);
    setError(null);

    try {
      const rankingResponse = await fetch(`${window._env_.BACKEND_URL}/ranking`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ report_url: reportUrl })
      });

      if (rankingResponse.status !== 200) {
        const text = await rankingResponse.text();
        setError(`${rankingResponse.status} ${rankingResponse.statusText}: ${text}`);
        return;
      }

      const r = await rankingResponse.json();
      if (!r?.talents) {
        setError("Invalid report URL");
        return;
      }

      setRanking(r.talents);
      setReportType(r.type);
    } catch (error) {
      setError(`An error occurred: ${error}`);
    } finally {
      setTalentsLoading(false);
    }
  }

  async function handleGenerate() {
    if (!ranking) {
      setError("Please load talents first");
      return;
    }

    setPromptLoading(true);
    setError(null);

    try {
      const promptResponse = await fetch(`${window._env_.BACKEND_URL}/prompt`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ model: model.model, prompt: prompt.value, team_ranking: ranking, report_type: reportType }),
      });

      if (promptResponse.status !== 200) {
        const text = await promptResponse.text();
        setError(`${promptResponse.status} ${promptResponse.statusText}: ${text}`);
        return;
      }

      const json = await promptResponse.json();
      setResult(json);

      setIsShowingSaved(false);
    } catch (error) {
      setError(error as string);
    } finally {
      setPromptLoading(false);
    }
  }

  async function handleSave() {
    if (!result) {
      setError("Please generate a report first");
      return;
    }

    setSaveLoading(true);
    setError(null);

    try {
      const saveResponse = await fetch(`${window._env_.BACKEND_URL}/save`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ name: saveName, model: model.model, prompt: prompt.value, ranking: ranking, report_type: reportType, report_url: reportUrl, response_json: JSON.stringify(result) }),
      });

      if (saveResponse.status !== 200) {
        const text = await saveResponse.text();
        setError(`${saveResponse.status} ${saveResponse.statusText}: ${text}`);
        return;
      }

      setSaveName("");
      loadReports();
    } catch (error) {
      setError(error as string);
    } finally {
      setSaveLoading(false);
    }
  }

  async function loadReports() {
    const reportsResponse = await fetch(`${window._env_.BACKEND_URL}/reports`);
    const reports = await reportsResponse.json();
    setReports(reports);
  }

  async function loadReport() {
    if (!selectedReport) {
      setError("Please select a report first");
      return;
    }

    const report = reports.find(r => r.id === selectedReport.id);
    if (!report) {
      setError("Report not found");
      return;
    }

    setModel(models.find(m => m.model === report.model)!);
    setPrompt(prompts.find(p => p.value === report.prompt)!);
    setReportType(report.report_type as "individual" | "team");
    setReportUrl(report.report_url);
    setRanking(report.ranking);
    setResult(JSON.parse(report.response_json));

    setIsShowingSaved(true);
  }

  useEffect(() => {
    loadReports();
  }, []);

  return (
    <div className="bg-gray-100 min-h-screen py-10 px-8">
      <h1 className="text-4xl font-bold text-center mb-10">TT38 GenAI playground</h1>
      <div className="flex justify-center items-center flex-col gap-4">
        <div className="bg-white p-8 rounded-lg shadow-md w-full md:w-full lg:w-3/4 xl:w-4/6">
          <div className="flex mb-4 gap-2 border-b-2">
            <div
              onClick={() => setTab('generate')}
              className={`cursor-pointer px-4 py-2 ${tab === 'generate' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
            >
              Generate
            </div>
            <div
              onClick={() => setTab('load')}
              className={`cursor-pointer px-4 py-2 ${tab === 'load' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
            >
              Load
            </div>
          </div>
          {tab === 'generate' && (
            <>
              <div className="flex flex-col mb-4 gap-2">
                <label htmlFor="team_ranking" className="font-bold mr-4">Talent Report</label>
                <input
                  type="text"
                  id="team_ranking"
                  className="border border-gray-300 rounded-md p-2"
                  value={reportUrl}
                  onChange={(e) => setReportUrl(e.target.value)}
                />
              </div>

              <button onClick={loadTalents} className="bg-blue-500 text-white px-4 py-2 mt-2 mb-6 rounded-md flex items-center" disabled={talentsLoading}>
                {talentsLoading && <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>}
                Load Talents
              </button>

              <div className="flex flex-col mb-4 gap-2">
                <label htmlFor="model" className="font-bold mr-4">Model</label>
                <div className="relative">
                  <select
                    id="model"
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-gray-200 focus:border-gray-500"
                    value={model.model}
                    onChange={(e) => setModel(models.find(m => m.model === e.target.value)!)}
                  >
                    {models.map(model => <option key={model.model} value={model.model}>{model.name}</option>)}
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                  </div>
                </div>
                <p className="text-gray-500 text-sm">{model.description} (input: ${model.price.inputPerM}/million tokens, output: ${model.price.outputPerM}/million tokens)</p>
              </div>

              <div className="flex flex-col mb-4 gap-2">
                <label htmlFor="prompt" className="font-bold mr-4">Prompt</label>
                <div className="relative">
                  <select
                    id="prompt"
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-gray-200 focus:border-gray-500"
                    value={prompt.value}
                    onChange={(e) => setPrompt(prompts.find(p => p.value === e.target.value)!)}
                  >
                    {prompts.map(prompt => <option key={prompt.value} value={prompt.value}>{prompt.name}</option>)}
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                  </div>
                </div>
              </div>

              <button onClick={handleGenerate} className="bg-blue-500 text-white px-4 py-2 mt-2 rounded-md flex items-center" disabled={promptLoading}>
                {promptLoading && <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>}
                Generate
              </button>

              {error && <div className="text-red-500 mt-4">{typeof error === 'string' ? error : JSON.stringify(error)}</div>}
            </>
          )}
          {tab === 'load' && (
            <>
              <div className="flex flex-col mb-4 gap-2">
                <label htmlFor="team_ranking" className="font-bold mr-4">Load Report</label>
                <select
                  id="report"
                  className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-gray-200 focus:border-gray-500"
                  value={selectedReport?.id}
                  onChange={(e) => setSelectedReport(reports.find(r => r.id === e.target.value) || null)}
                >
                  <option value="">Select a report</option>
                  {reports.map(report => <option key={report.id} value={report.id}>{report.name}</option>)}
                </select>
              </div>

              <button onClick={loadReport} className="bg-blue-500 text-white px-4 py-2 mt-2 mb-6 rounded-md flex items-center">
                Load Report
              </button>
            </>
          )}
        </div>

        {(ranking || result) && (
          <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-4 sm:w-full md:w-full lg:w-3/4 xl:w-4/6">
            <div className="bg-white p-8 rounded-lg shadow-md w-full">
              <div className="flex flex-col gap-2">
                <h2 className="text-3xl font-bold">Talents ({reportType === "individual" ? "Individual" : "Team"})</h2>
                <p className="italic text-gray-600 mb-4">Drag to reorder</p>
                {ranking && (
                  <ul className="text-gray-700">
                    {ranking.map((talent, index) => (
                      <li
                        key={index}
                        className={`
                          py-2 border-b ${dragOverIndex !== index ? 'border-gray-300' : ''} px-4 
                          ${getStripedBgColor(index)} 
                          cursor-grab select-none
                          relative
                          ${dragOverIndex === index && dragPosition === 'top' ? 'border-t-1 border-blue-500' : ''}
                          ${dragOverIndex === index && dragPosition === 'bottom' ? 'border-b-1 border-blue-500' : ''}
                        `}
                        draggable={true}
                        onDragStart={(e) => {
                          e.dataTransfer.setData("text/plain", index.toString());
                        }}
                        onDragOver={(e) => {
                          e.preventDefault();
                          const rect = e.currentTarget.getBoundingClientRect();
                          const middle = rect.top + rect.height / 2;
                          const newPosition = e.clientY <= middle ? 'top' : 'bottom';

                          if (dragOverIndex === index && dragPosition === newPosition) {
                            return;
                          }

                          setDragOverIndex(index);
                          setDragPosition(newPosition);
                        }}
                        onDragLeave={(e) => {
                          const rect = e.currentTarget.getBoundingClientRect();
                          const mouseEvent = e.nativeEvent;
                          if (
                            mouseEvent.clientX < rect.left ||
                            mouseEvent.clientX >= rect.right ||
                            mouseEvent.clientY < rect.top ||
                            mouseEvent.clientY >= rect.bottom
                          ) {
                            if (dragOverIndex === index) {
                              setDragOverIndex(null);
                              setDragPosition(null);
                            }
                          }
                        }}
                        onDrop={(e) => {
                          e.preventDefault();
                          const fromIndex = Number(e.dataTransfer.getData("text/plain"));
                          const updatedRanking = [...ranking];

                          const rect = e.currentTarget.getBoundingClientRect();
                          const middle = rect.top + rect.height / 2;
                          const targetIndex = e.clientY <= middle ? index : index + 1;

                          if (fromIndex === targetIndex || fromIndex === targetIndex - 1) {
                            return;
                          }

                          const talent = updatedRanking.splice(fromIndex, 1)[0];
                          updatedRanking.splice(targetIndex > fromIndex ? targetIndex - 1 : targetIndex, 0, talent);

                          setRanking(updatedRanking);
                          setDragOverIndex(null);
                          setDragPosition(null);
                        }}
                        onDragEnd={(e) => {
                          e.dataTransfer.clearData();
                          setDragOverIndex(null);
                          setDragPosition(null);
                        }}
                      >
                        {index + 1}. {talent.charAt(0).toUpperCase() + talent.slice(1)}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>

            <div className="bg-white p-8 rounded-lg shadow-md w-full">
              <h2 className="text-3xl font-bold mb-4">Result</h2>
              {isShowingSaved && (
                <p className="text-gray-500 mb-4"><b>Currently showing:</b> {selectedReport?.name}</p>
              )}
              {promptLoading && (
                <div className="flex items-center gap-4 pb-4">
                  <p className="text-gray-500">Generating...</p>
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </div>
              )}
              {result && (
                <div>
                  {/* @ts-ignore */}
                  {printContent(result.content)}
                  <hr className="my-4" />
                  <p className="text-gray-500">Processing took {result.elapsed_time.toFixed(2)} seconds</p>
                  <p>Token usage: {result.usage.prompt_tokens} in prompt (input), {result.usage.completion_tokens} in completion (output)</p>
                  <p>Estimated cost: ${(result.usage.prompt_tokens * model.price.inputPerM / 1000000 + result.usage.completion_tokens * model.price.outputPerM / 1000000).toFixed(5)}</p>

                  {!isShowingSaved && (
                    <>
                      <div className="flex flex-col gap-2">
                        <label htmlFor="save_name" className="font-bold mr-4">Name</label>
                        <input
                          type="text"
                          id="save_name"
                          className="border border-gray-300 rounded-md p-2"
                          value={saveName}
                          placeholder={`${ranking?.[0]}, ${ranking?.[1]}, ${ranking?.[2]}...`}
                          onChange={(e) => setSaveName(e.target.value)}
                        />
                      </div>
                      <button onClick={handleSave} className="bg-blue-500 text-white px-4 py-2 mt-2 rounded-md flex items-center" disabled={saveLoading}>
                        {saveLoading && <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>}
                        Save
                      </button>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        )}

      </div>
    </div>
  );
}

const getStripedBgColor = (index: number) => {
  if (index < 12) {
    if (index % 2 === 1) {
      return 'bg-green-100';
    }
    return 'bg-green-200';
  }
  if (index > 26) {
    if (index % 2 === 1) {
      return 'bg-red-100';
    }
    return 'bg-red-200';
  }
  if (index % 2 === 1) {
    return 'bg-white';
  }
  return 'bg-gray-100';
}

const printContent = (content: Record<string, string | string[]>) => {
  return Object.entries(content).map(([key, value]) => (
    <div key={key} className="mb-4">
      <h3 className="text-lg font-bold">{key.charAt(0).toUpperCase() + key.replace("_", " ").slice(1)}</h3>
      {Array.isArray(value) ? (
        <ul className="list-disc list-inside text-gray-700">
          {value.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      ) : (
        <p className="text-gray-700">{value}</p>
      )}
    </div>
  ));
}

export default App;

